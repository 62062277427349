import React from 'react'
import Img1 from '../assets/logo.png'
const Logo = () => {
  return (
    <div className="logo">
      <img src={Img1} alt="logo" />
    </div>
  )
}

export default Logo